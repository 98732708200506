import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from "../components/pages/LoginPage"
import WelcomePage from "../components/pages/Welcome";
import ProjectPage from "../components/pages/ProjectPage";
import SkillsPage from "../components/pages/SkillsPage";
import AboutPage from "../components/pages/AboutPage";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ProjectPage
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: WelcomePage
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage
  },
  {
    path: "/skills",
    name: "Skills",
    component: SkillsPage
  },
  {
    path: "/about",
    name: "About",
    component: AboutPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
