<template>
    <div>
        <div class="ui basic modal">
            <div class="ui icon header">
                Get in Touch
            </div>
            <div class="content">
                <form class="ui form">
                    <div class="field">
                        <span class="ui text white">Name</span>
                        <input type="text" id="name" name="first-name" placeholder="Name">
                    </div>
                    <div class="field">
                        <span class="ui text white">Email</span>
                        <input type="text" id="sendersEmail" name="email" placeholder="Email Address">
                    </div>
                    <div class="field">
                            <span class="ui text white">Message</span>
                            <textarea id="message" placeholder="Enter your message here"></textarea>
                        </div>  
                    </form>
            <div class="actions">
                <div class="ui red basic cancel inverted button">
                <i class="remove icon"></i>
                Cancel
                </div>
                <div class="ui green ok inverted button">
                <i class="checkmark icon"></i>
                Send Message
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name: "ContactModal",
}
</script>

<style scoped>
label {
    color: white;
}

.actions {
    margin: 30px;
}
</style>