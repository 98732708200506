<template>
    <div class="project-page">
        <span>
         {{ explainingText }}
        </span>
        <div class="card-container">
        <div v-if="loading" class="loading">
            <ProgressBar/>
        </div>
       
        <div v-for="project in projects" :key="project">
            <div class="card">
            <ProjectCards
            :name="project.name"
            :deployed="project.deployed"
            :description="project.description"
            :githubURL="project.githubUrl"
            :image="project.image"
            :productionURL="project.productionURL"
            :technologies="project.technologies"
            />
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import ProjectCards from "../cards/ProjectCards.vue";
import ProgressBar from "../loaders/ProgressBar.vue"
import axios from "axios";


export default {
    name: "ProjectPage",
    components: {
       ProjectCards,
       ProgressBar,
       
    },
    data() {
        return {
            projects: null,
            loading: true,
            explainingText: null
    }
    },
    mounted() {
        let menuItem = document.getElementById("projects");
        menuItem.classList.add("active");

        axios.get("/api/projects/all")
             .then(res => {
                 this.projects = res.data;
                 this.loading = false;
                 this.explainingText = "Hover over the pictures to view the source code for each project, or click on the title to view the application in production."
             })
    }
}
</script>

<style scoped>
    .project-page {
        margin-top: 20px;
        padding: 20px;
    }

    .card-container {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
    }

    .card {
        margin: 20px;
    }

    .loading {
        width: 100%;
    }

    span {
        font-size: 14px;
        padding: 20px;
        width: 80%;
        margin: auto;
    }
</style>