<template>
    <div>
        <div class="ui blue indeterminate progress">
            <div class="bar">
                <div class="progress">Loading...</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProgressBar"
}
</script>
