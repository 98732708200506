<template>
<div>
  <div  class="app-header">
    <MainHeader/>
    <MainMenu/>
  </div>
  <div class="app-content">

  <router-view/>
  </div>
  <ContactModal/>
</div>
</template>

<script>
import MainHeader from "./components/headers/MainHeader.vue";
import MainMenu from "./components/menus/MainMenu.vue";
import ContactModal from "./components/modals/ContactModal.vue"

export default {
    components: {
        MainHeader,
        MainMenu,
        ContactModal
    }
}
</script>

<style>
@import "../semantic/dist/semantic.css";

.app-content {
  margin: auto;
  max-width: 80%;
  background-color: white;
  max-height: 90%;
}

#app {
  background-color: #514c6818;
  min-height: 100%;
}

.app-header {
  background-color: white;
}
</style>
