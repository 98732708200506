<template>
<div class="skills-page">
    <div class="card-container">
        <div class="progress" v-if="loading">
            <ProgressBar/>
        </div>
        <div class="card" v-for="skill in skills" :key="skill">
            <SkillCards
            :backEnd="skill.backEnd"
            :frontEnd="skill.frontEnd"
            :image="skill.image"
            :name="skill.name"
            />
        </div>

     </div>
</div>
</template>

<script>
import axios from "axios";
import SkillCards from "../cards/SkillCards.vue"
import ProgressBar from "../loaders/ProgressBar.vue"

export default {
    name: "SkillPage",
    components: {
        SkillCards,
        ProgressBar
    },
    data() {
        return {
            skills: Array,
            loading: true
        }
    },
    mounted() {
        let skillMenu = document.getElementById("skills");
        skillMenu.classList.add("active");
        axios.get("/api/skills/all")
             .then(res => {
                 this.skills = res.data;
                 this.loading = false;
             })
    }
}
</script>

<style scoped>
    .skills-page {
        margin-top: 20px;
    }

    .card-container {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
    }

    .card {
        margin: 20px;
    }

    .progress {
        width: 100%;
    }
</style>