<template>
    <div :v-if="authenticated">
        {{welcome}}
        Wassup bitch
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "WelcomePage",
    data() {
        return {
        welcome: String,
        authenticated: false
        }
    },
    mounted() {
        let config = {
            headers: {
                "x-access-token": this.$cookies.get("access_token")
            }
        };
        axios.get("/api/users/welcome", config)
             .then(response => {
                 this.welcome = response.data.message;
                 this.authenticated = true;
             })
             .catch(() => {
                 this.$router.push("/login");
    })
    }
}
</script>