<template>
    <div>
        <div class="ui special cards">
            <div class="card">
                <div class="blurring dimmable image">
                <div class="ui dimmer">
                    <div class="content">
                    <div class="center">
                        <a :href="githubURL" class="ui inverted button">View On Github</a>
                    </div>
                    </div>
                </div>
                <img :src="image" @mouseover="dimImage">
                </div>
                <div class="content">
                <a :href="productionURL" class="header">{{ name }}</a>
                <div class="meta">
                    <span>{{ description }}</span>
                </div>
                </div>
                <div class="extra content">
                    <div v-for="technology in technologies" :key="technology">{{ technology }}</div>
                </div>
            </div>
    </div>
    </div>
</template>

<script>
export default {
    name: "ProjectCards",
    props: {
        name: String,
        githubURL: String,
        technologies: Array,
        deployed: Boolean,
        productionURL: String,
        image: String,
        description: String
    },
    methods: {
        dimImage() {
        window.$('.special.cards .image').dimmer({
        on: 'hover'
        });
        }
    }
}
</script>