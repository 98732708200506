<template>
    <div>
        <LoginForm/>
    </div>
</template>

<script>
import LoginForm from "../forms/Login.vue";

export default {
    name: "LoginPage",
    components: {
        LoginForm
    }
}
</script>