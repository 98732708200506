<template>
<div>
  <form action="/api/authorizer/login" method="POST" class="ui form">
      <div class="field">
        <label for="username">Username: </label>
        <input type="text" name="username"/>
      </div>
      <div class="field">
        <label for="password">Password: </label>
        <input type="password" name="password"/>
      </div>
        <button class="ui button" type="SUBMIT">Submit</button>
  </form>
</div>
</template>

<script>
    export default {
        name: "LoginForm"
}
</script>

<style>

</style>