<template>
    <div>
        <div class="ui card">
        <div class="image">
            <img :src="image">
        </div>
        <div class="content">
            <div class="header">{{ name }}</div>
        </div>
        <div class="extra content">
            <div v-if="frontEnd">
                Front End Development
            </div>
            <div v-if="backEnd">
                Back End Development
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SkillCards",
    props: {
        name: String,
        image: String,
        frontEnd: Boolean,
        backEnd: Boolean
    },
    methods: {
        dimImage() {
        window.$('.special.cards .image').dimmer({
        on: 'hover'
        });
        }
    }
}
</script>