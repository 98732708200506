<template>

    <div class="main-header">
        <div class="main-header-info">
            <h1>Derek Smith</h1>
            <div class="ui list">
        <div class="item">
            <i class="code icon"></i>
            <div class="content">
                Full Stack Developer
            </div>
        </div>
        <div class="item">
            <i class="marker icon"></i>
            <div class="content">
            Saint Louis, Missouri
            </div>
        </div>
        <div class="item">
            <i class="mail icon"></i>
            <div class="content">
            <a href="mailto:dereksmv@gmail.com">Dereksmv@gmail.com</a>
            </div>
        </div>
        <div class="item">
            <i class="phone icon"></i>
            <div class="content">
            <a>573-239-4852</a>
            </div>
        </div>
        </div>
        </div>
        <div class="large screen only">
        <div class="main-header-buttons">
            <button class="ui button primary" @click="showModal">Contact</button>
            <button class="ui button" @click="downloadResume">Download Resume</button>
        </div>
        </div>
    </div>
</template>

<style scoped>
    .main-header {
        padding-top: 20px;
        display: grid;
        grid-template-columns: 80% 20%;
        padding-bottom: 20px;
        margin: auto;
        width: 95%;
    }

</style>

<script>
import axios from "axios";

export default {
    name: "MainHeader",
    methods: {
        downloadResume() {
                const method = 'GET';
                const url = '/api/download/resume';
                axios.request({url, method, responseType: 'blob'})
                     .then(({ data }) => {
                            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                            const link = document.createElement('a');
                            link.href = downloadUrl;
                            link.setAttribute('download', 'dereksmithfullstack.docx'); 
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                            window.$('body')
                            .toast({
                            message: 'Thanks for downloading my resume!'
                            })
                            });
                            },

                showModal() {
                    window.$('.ui.modal').modal({onApprove : function() {
                        let sendersEmail = document.getElementById("sendersEmail").value;
                        let message = document.getElementById("message").value;
                        let name = document.getElementById("name").value;
                        let body = {
                            sendersEmail,
                            message,
                            name
                        }
                        console.log(sendersEmail)
                        axios.post("/api/contact/email", body)
                             .then(res => {
                                 window.$('body').toast({
                                     title: "Success",
                                     position: "top attached",
                                     class: "success",
                                     message: res.data.message
                                 })
                             })
                    }}).modal('show');
                    }
                        }
                    }

</script>
