<template>
    <div>
        <div class="ui secondary pointing menu">
            <a href="/" class="item" id="projects">
                Projects
            </a>
            <a href="/skills" class="item" id="skills">
                Skills
            </a>
            <a href="/about" class="item" id="about">
                About Me
            </a>
            </div>
    </div>
</template>

<script>
export default {
    name: "MainMenu"
}
</script>