<template>
    <div class="about">
    <h1 class="ui header">About Me</h1>
    Enthusiastic developer and fast learner with a passion for writing clean, readable code that delivers strong user experiences. Prior to working in technical roles, I was a highly motivated digital content creator with five years of experience developing content for all stages of the sales cycle. In my current role I specialize in creating requirements and turning them into viable UI/UX designs. In my projects, I've gained experience creating single page web applications using modern JavaScript frameworks and tooling, using REST APIs, generated through numerous server-side languages.
    <h1 class="ui header">Education</h1>
    <div class="ui list">
        <div class="item">University of Missouri, St. Louis, BA in Communication</div>
        <div class="item">FreeCodeCamp.org Graduate, 2019</div>
        <div class="item">Graduate Certificate in Internet and Web Development <strong>(in progress)</strong></div>
    </div>
    <h1 class="ui header">Technical Skills</h1>
    JavaScript, API | React.js, Node.js | HTML5 | CSS3 | C# | .NET | RESTful | Typescript | Git, GitLab, GitHub | Python | XML | MongoDB | SQL | Azure DevOps, Active Directories | Jira | Aha! | Zapier | Okta | Vue | Spring Framework, Rest, MVC | ES6 | Babel | SASS/LESS | Bootstrap | HTTP | Cookies | Asset Loading | Caching | Facebook Ads Manager | Wrike
    <h1 class="ui header">Socials</h1>
        <div class="ui list">
            <a href="https://www.github.com/lotg515" class="item"><i class="large github middle aligned icon"></i>Github</a>
            <a href="https://www.linkedin.com/in/derek-smith-cspo-155378b9/" class="item"><i class="large linkedin middle aligned icon"></i>LinkedIn</a>
        </div>
    </div>
    
</template>

<script>
export default {
    name: "AboutPage",
    mounted() {
        document.getElementById("about").classList.add("active");
    }
}
</script>

<style>
    .about {
        margin: 20px;
    }
</style>